import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;

const Button = styled.div`
  display: flex;
  width: 45%;
  height: 80%;
  justify-content: center;
  align-items: center;
  background-color: #202731;
  border-radius: 4px;
  margin: 1em;
  font-size: 20px;
`;
function StatsTeamSelect() {
  let { gameID } = useParams();
  const [getGame, { data }] = useLazyQuery(GAME_BY_ID);
  const [game, setGame] = useState();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  return (
    <Main>
      <Button
        onClick={() => {
          history.push({
            pathname: "/players/" + gameID + "/home",
          });
        }}
      >
        {game?.home_team?.name}
      </Button>
      <Button
        onClick={() => {
          history.push({
            pathname: "/players/" + gameID + "/away",
          });
        }}
      >
        {game?.away_team?.name}
      </Button>
    </Main>
  );
}

export default StatsTeamSelect;
