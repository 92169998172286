import React from "react";
import styled from "styled-components";
import moment from "moment";
const Button = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  height: 5%;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: cetner;
`;
const Caption = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
`;
export default function Player({ player, previewGraphic, game, period }) {
  return (
    <>
      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,
            team: player?.team,
            game,
          });
        }}
      >
        Name
      </Button>

      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,
            team: player?.team,
            game,
            no_number: true,
          });
        }}
      >
        Name no number
      </Button>

      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("POTM", {
              player: player,
              subtext: "BETFRED PLAYER OF THE MATCH",
              team: player?.team,
              game,
            });
          }}
        >
          Player of the Match
        </Button>
      )}

      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 1,
              team: player?.team,
              game,
            });
          }}
        >
          Yellow Card
        </Button>
      )}

      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 3,
              team: player?.team,
              game,
            });
          }}
        >
          Red Card
        </Button>
      )}
      {player.stories &&
        player.stories.map((stat) => {
          return (
            <Button
              onClick={() => {
                previewGraphic("Name", {
                  team: player?.team,
                  player: player,

                  subtext: stat.story,

                  shirt_number: player.shirt_number || "",
                  game,
                });
              }}
            >
              <Caption>{stat.story}</Caption>
            </Button>
          );
        })}
    </>
  );
}
