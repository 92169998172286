import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import { ControlContext } from "../../contexts/ControlContext";

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 11vh;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
`;
const Middle = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;

  width: 100%;
`;
const Score = styled.div`
  display: flex;
  font-size: 3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
`;

const Stat = styled.div`
transform-origin: 0 0;
   transform: rotate(-90deg);
   max-width: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #5a5a5a;
    white-space: nowrap;
    padding-bottom: 9px;
`;
const Stats = styled.div`
  display:flex;
  width: 40%;
  margin-top: 141px;
`;
const CATEGORIES = [
  "Tackles",
  "Missed Tackles",
  "Breaks",
  "Handling errors",
  "Offloads",
  "Carries",
  "Metres",
  "Conv. Attempts",
];

export default function TeamNames({ setGoalScored, game, setSelectedPlayer, isFruit = false }) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { updateGame } = useContext(APIContext);
  const { clear } = useContext(ControlContext);

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
  let conversion_points =
    game?.competition?.competition_type === "union" ? 2 : 2;
  let penalty_kick_points =
    game?.competition?.competition_type === "union" ? 3 : 2;
  let penalty_try_points =
    game?.competition?.competition_type === "union" ? 7 : 4;
  let drop_goal_points =
    game?.competition?.competition_type === "union" ? 3 : 1;
  return (
    <TeamNamePanel>
      <Stats>
        <div style={{ width: '420px' }}></div>
        {CATEGORIES.map(cat => {
          return <Stat>{cat}</Stat>
        })}
      </Stats>
      <Middle>
        <Team
          style={{
            borderBottom:
              "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.home_team._id
                ? "1"
                : ".2",
          }}
        >
          <TeamName home style={{ display: "flex", justifyContent: "end" }}>
            {game.home_team.code}
          </TeamName>
          <Score
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {(game?.home_scorers?.filter((g) => g.type === "try").length *
              try_points || 0) +
              (game?.home_scorers?.filter((g) => g.type === "conversion").length *
                conversion_points || 0) +
              (game?.home_scorers?.filter((g) => g.type === "penalty_kick")
                .length * penalty_kick_points || 0) +
              (game?.home_scorers?.filter((g) => g.type === "penalty_try")
                .length * penalty_try_points || 0) +
              (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
                drop_goal_points || 0)}
          </Score>
        </Team>
        <Team
          style={{
            borderBottom:
              "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.away_team._id
                ? "1"
                : ".2",
          }}
        >
          <Score
            right
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {(game?.away_scorers?.filter((g) => g.type === "try").length *
              try_points || 0) +
              (game?.away_scorers?.filter((g) => g.type === "conversion").length *
                conversion_points || 0) +
              (game?.away_scorers?.filter((g) => g.type === "penalty_kick")
                .length * penalty_kick_points || 0) +
              (game?.away_scorers?.filter((g) => g.type === "penalty_try")
                .length * penalty_try_points || 0) +
              (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
                drop_goal_points || 0)}
          </Score>
          <TeamName>{game.away_team.code}</TeamName>
        </Team>

      </Middle>
      <Stats><div style={{ width: '420px' }}></div>

        {CATEGORIES.map(cat => {
          return <Stat>{cat}</Stat>
        })}
      </Stats>
    </TeamNamePanel>
  );
}
